@font-face {
  font-family: 'Moderat';
  src: local('Moderat'),
    url(/static/media/Moderat-Regular.4f1155c8.ttf) format('truetype');
  font-weight: 400; /* Regular (400) */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: 'Moderat';
  src: local('Moderat-Medium'),
    url(/static/media/Moderat-Medium.f656000e.ttf) format('truetype');
  font-weight: 500; /* Medium (500) */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: 'Moderat';
  src: local('Moderat-Bold'),
    url(/static/media/Moderat-Bold.a93e70c2.ttf) format('truetype');
  font-weight: 700; /* Bold (700) */
  font-style: normal; /* Normal style */
}
#root{
  position: relative;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Moderat' !important;
  /* transition: all .3s; */
}

.input-textfield{
  border: 1px solid #E3E3E3;
  outline: none;
  height:50px;
  /* padding: 15px 0; */
  padding-left: 15px;
  border-radius: 32px;
}
::-webkit-input-placeholder{
  color: #9AA0A1;
  font-size: 15px;
}
::placeholder{
  color: #9AA0A1;
  font-size: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #D9E7F5;
  border-radius: 10px;
  height: 200px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.text-area {
  font-family: 'popins';
  font-size: 15px;
  outline: none;
  padding: 15px;
  /* background-color: rgba(40, 60, 146, 0.11); */
  border-radius: 6px;
  resize: vertical;
  border: 1px solid #E3E3E3;
  outline: none;
  /* padding: 15px 0; */
  padding-left: 15px;
  border-radius: 22px;
}

.error-message{
  color: red;
  font-size: 14px;
  padding-left: 15px;
}


.react-multi-carousel-list{
  position: static !important;
}

.react-multi-carousel-item{
  padding: 0px .6rem;
}
.MuiBreadcrumbs-separator{
  color: #465152 !important;
}
.arrowRight{
 top: 32rem !important;
 right: 1.5rem !important;
 -webkit-transform: translate(0) !important;
         transform: translate(0) !important;
}

.arrowLeft{
  top:32rem !important;
  left: 1.5rem !important;
  -webkit-transform: translate(0) !important;
          transform: translate(0) !important
}
/* .arrowRight{
 transform: translate(-30%,50%) !important;
 position: fixed !important;
 top:40% !important
}

.arrowLeft{
  transform: translate(30%,50%) !important;
  position: fixed !important;
  top:40% !important
} */

@media only screen and (max-width : 767px ) {
  .react-multi-carousel-item{
    padding: 0px .4rem;
  }
}

.whatsapp_float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 18px;
    right: 12px;
    /* background-color: #25d366; */
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}
